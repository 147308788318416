import { Loader } from "./component/Loader";
import Faq from "./Pages/Faq";
// import Profile from "./Pages/Profile";
import Purchase from "./Pages/Purchase.jsx";
import Howtoplay from "./Pages/Howtoplay";
import "./App.css";
import { Login } from "./component/Login";
import { Register } from "./component/Register";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import { Dashboard } from "./component/Dashboard/Dashboard";
import Home from "./Pages/Home";
import Header from "./component/Header";
import Footer from "./component/Footer";
// import ChallengeSomeone from "./component/ChallengeSomeone";
//Not remove this code we just comment this so we can use this on future
// import Marketplace from "./Pages/Marketplace";
// import BuyDuelCard from "./Pages/BuyDuelCard";
// import ChallengeReceived from "./component/ChallengeReceived";
// import DuelAccepted from "./component/DuelAccepted";
// import Auction from "./component/Auction";
// import Winner from "./Pages/Winner";
// import Leaderboard from "./Pages/Leaderboard";
// import AboutRules from "./Pages/AboutRules";
// import Thankyou from "./Thankyou";
// import ShopStreamer from "./component/ShopStreamer";
// import ShopUniversal from "./component/ShopUniversal";
// import ShopSeasonal from "./component/ShopSeasonal";
import Contactus from "./Pages/Contactus";
import { DuelStatus } from "./Pages/DuelStatus";
// import { SoloShowDownStatus } from "./Pages/SoloShowDownStatus"
// import Pathtolevel from "./Pages/Pathtolevel";
import { Loser } from "./Pages/Loser";
// import Duelsystem from "./component/Duelsystem";
import { Decisioninfo } from "./component/Decisioninfo";
import { RegisterSuccess } from "./component/RegisterSuccess";
import { VerifyUser } from "./component/VerifyUser";
import React, { Component, Suspense, useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
// import { CardGallery } from "./Pages/CardGallery";
import { Admin } from "./Admin/Admin";
import { PublicChallenge } from "./Pages/PublicChallenge";
import { PublicRecieve } from "./Pages/PublicRecieve";
import { Challengedetails } from "./Admin/Challengedetails";
import { useDispatch, useSelector } from "react-redux";
import { loaduser } from "./actions/userAction";
import { Table_user } from "./Admin/Table_user.jsx";
import { Table_challenge } from "./Admin/Table_challenge";
import { Settings } from "./Admin/Settings";
import { Coupon } from "./Admin/Coupon";
import { Dispute } from "./Admin/Dispute";
import { AdminLogin } from "./Admin/AdminLogin";
import TermsOfService from "./Pages/TermsOfService";
import UserDataPolicy from "./Pages/UserDataPolicy";
import OverWolf from "./component/OverWolf";
import ForgetPassword from "./component/ForgetPassword";
import ResetPassword from "./component/ResetPassword";
import NotFound from "./Pages/NotFound";
import { CashoutTransactions } from "./Pages/CashoutTransactions";
import { TransactionLog } from "./Admin/TransactionLog";
import {UserTransactionslog} from "./Pages/UserTransactionslog"
// import SoloShowDown from "./component/SoloShowDown";
// import LiveSoloShowDown from "./component/LiveSoloShowDown";
import {MonitorFunds} from "./Admin/MonitorFunds";
import moment from "moment";
import useIdleTimeout from "./hooks/idleHook";
const ChallengeSomeone = React.lazy(() => import("./component/ChallengeSomeone"));
const ChallengeReceived = React.lazy(() => import("./component/ChallengeReceived"));
const DuelAccepted = React.lazy(() => import("./component/DuelAccepted"));
const Winner = React.lazy(() => import("./Pages/Winner"));
const Thankyou = React.lazy(() => import("./Thankyou"));
const ShopStreamer = React.lazy(() => import("./component/ShopStreamer"));
const ShopUniversal = React.lazy(() => import("./component/ShopUniversal"));
const ShopSeasonal = React.lazy(() => import("./component/ShopSeasonal"));
const SoloShowDownStatus = React.lazy(() => import("./Pages/SoloShowDownStatus"));
const Profile = React.lazy(() => import("./Pages/Profile"));
const SoloShowDown = React.lazy(() => import("./component/SoloShowDown"));
const LiveSoloShowDown = React.lazy(() => import("./component/LiveSoloShowDown"));

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { isIdle,setIdle } = useIdleTimeout({ onIdle: () => updateUserLastActive(), idleTime:60 })
  // dispatch(loaduser());


  const getexpire = async () => {
    await axios.put("/api/auth/setexpire", { date: Date.now() });
  };

  useEffect(() => {
    getexpire();
    // getrecieved()
    try {
      if(localStorage.getItem("nftuser")){
        console.log("app");
        dispatch(loaduser(navigate));
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updateUserLastActive = async () => {
    const userdetails = localStorage.getItem("nftuser");
    if (userdetails) {
       await axios.post("/api/auth/updateUserlastactive", {
        _id: JSON.parse(userdetails)._id,
        last_active: moment().toISOString(),
      });
    }
    setIdle(false)
  }
  let location = useLocation();

  return (
    <>
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-MSCM2V9R"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* End Google Tag Manager (noscript) */}

      <div className="App">
        {location.pathname !== "/admin" &&
          location.pathname !== "/admin/users" &&
          location.pathname !== "/admin/challenges" &&
          location.pathname !== "/admin/settings" &&
          location.pathname !== "/admin/coupon" &&
          location.pathname !== "/admin/transactionlog" &&
          location.pathname !== "/admin/monitorfunds" &&
          location.pathname !== "/admin/disputes" &&
          location.pathname !== "/admin/login" && <Header />}

        <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />


          <Route
            path="/login"
            element={
              <PrivateRoute>
                <Login />
              </PrivateRoute>
            }
          />
          <Route
            path="/register"
            element={
              <PrivateRoute>
                <Register />
              </PrivateRoute>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <PrivateRoute>
                <ForgetPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/resetpassword/:resetToken"
            element={
              <PrivateRoute>
                <ResetPassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile/:active"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/purchase/:id"
            element={
              <ProtectedRoute>
                <Purchase />
              </ProtectedRoute>
            }
          />
          <Route path="/CashoutTransactions" element={<CashoutTransactions />} />
          <Route path="/UserTransactionslog" element={<UserTransactionslog />} />
          {/* <Route path="/BuyDuelCard" element={<BuyDuelCard />} /> */}
          <Route path="/faqs" element={<Faq />} />
          <Route path="/terms-service" element={<TermsOfService />} />
          <Route path="/data-policy" element={<UserDataPolicy />} />
          <Route path="/winner/:id/:index/:challengeno" element={<Winner />} />
          <Route path="/loser/:id/:index/:challengeno" element={<Loser />} />
          <Route path="/loser/:id/:index/:challengeno" element={<Loser />} />
          <Route
            path="/ChallengeSomeone"
            element={
              // <ProtectedRoute>
                <ChallengeSomeone />
              // </ProtectedRoute>
            }
          />
          {/* <Route path="/AboutRules" element={<AboutRules />} /> */}
          {/* <Route path="/Marketplace" element={<Marketplace />} /> */}
          <Route
            path="/ChallengeReceived"
            element={
              <ProtectedRoute>
                <ChallengeReceived />
              </ProtectedRoute>
            }
          />
          <Route path="/DuelAccepted" element={<DuelAccepted />} />
          <Route path="/DuelAccepted/:challengeid" element={<DuelAccepted />} />
          {/* <Route path="/Auction" element={<Auction />} /> */}
          {/* <Route path="/leaderboard" element={<Leaderboard />} /> */}
          <Route path="/thankyou/:id/:challangedate" element={<Thankyou />} />
          <Route path="/registersuccess" element={<RegisterSuccess />} />
          <Route path="/VerifyUser" element={<VerifyUser />} />
          <Route path="/shop-streamer" element={<ShopStreamer />} />
          <Route path="/shop-universal" element={<ShopUniversal />} />
          <Route path="/shop-seasonal" element={<ShopSeasonal />} />
          <Route path="/contact-us" element={<Contactus />} />
          <Route
            path="/DuelStatus"
            element={
              <ProtectedRoute>
                <DuelStatus />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SoloShowDownStatus"
            element={
              <ProtectedRoute>
                <SoloShowDownStatus />
              </ProtectedRoute>
            }
          />
          
          {/* <Route path="/pathtolevel" element={<Pathtolevel />} /> */}
          <Route path="/auth/overwolf" element={<OverWolf />} />
          {/* <Route path="/duelsystem" element={<Duelsystem />} /> */}
          <Route path="/decinfo" element={<Decisioninfo />} />
          {/* <Route
            path="/cardgallery"
            element={
              <ProtectedRoute>
                <CardGallery />
              </ProtectedRoute>
            }
          /> */}

          <Route
            path="/challengemarketplace"
            element={
              // <ProtectedRoute>
                <PublicChallenge homepage={{ isOpen:false }}/>
              // </ProtectedRoute>
            }
          />
          <Route path="/howtoplay" element={<Howtoplay />} />

          <Route
            path="/publicrecieve/:id"
            element={
              <ProtectedRoute>
                <PublicRecieve />
              </ProtectedRoute>
            }
          />

          <Route
            path="/challengedetails/:id"
            element={
              <ProtectedRoute>
                <Challengedetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/soloshowdown"
            element={
              <ProtectedRoute>
                <SoloShowDown />
              </ProtectedRoute>
            }
          />
          <Route
            path="/livesoloshowdown"
            element={
              <ProtectedRoute>
                <LiveSoloShowDown />
              </ProtectedRoute>
            }
          />
          <Route
            path="/livesoloshowdown/:challengeid"
            element={
              <ProtectedRoute>
                <LiveSoloShowDown />
              </ProtectedRoute>
            }
          />
          {/* admin routes */}
          <Route path="/admin/login" element={<AdminLogin />} />
          {/* <Route
            path="/admin"
            element={
              <AdminRoutes>
                <Admin />
              </AdminRoutes>
            }
          /> */}
          <Route
            path="/admin/challenges"
            element={
              <AdminRoutes>
                <Table_challenge />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin/users"
            element={
              <AdminRoutes>
                <Table_user />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <AdminRoutes>
                <Settings />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin/coupon"
            element={
              <AdminRoutes>
                <Coupon />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin/monitorfunds"
            element={
              <AdminRoutes>
                <MonitorFunds />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin/transactionlog"
            element={
              <AdminRoutes>
                <TransactionLog />
              </AdminRoutes>
            }
          />
          <Route
            path="/admin/disputes"
            element={
              <AdminRoutes>
                <Dispute />
              </AdminRoutes>
            }
          />
        <Route path="*" element={<NotFound />} />
          {/* <Route path = "/adminlogin" element={<AdminLogin/>}/> */}

          {/* <Route path = "/duelmarket" element = {<ChallengeMarket/>}/> */}
        </Routes>
        </Suspense>
        {location.pathname !== "/admin" &&
          location.pathname !== "/admin/users" &&
          location.pathname !== "/admin/challenges" &&
          location.pathname !== "/admin/settings" &&
          location.pathname !== "/admin/coupon" &&
          location.pathname !== "/admin/transactionlog" &&
          location.pathname !== "/admin/monitorfunds" &&
          location.pathname !== "/admin/disputes" &&
          location.pathname !== "/admin/login" && <Footer />}
        <ToastContainer />
      </div>
    </>
  );
}

export function ProtectedRoute(props) {
  const userdata = localStorage.getItem("nftuser");

  if (userdata) {
    return props.children;
  } else {
    return <Navigate to="/login" />;
  }
}

export function PrivateRoute(props) {
  if (!localStorage.getItem("nftuser")) {
    return props.children;
  } else {
    return <Navigate to="/ChallengeSomeone" />;
  }
}

export function AdminRoutes(props) {
  const [isAdmin, setAdmin] = useState(false);
  const checkAdmin = async () => {
    const { status } = await axios.get("/api/auth/verifyAdmin");
    if (status == "200") setAdmin(true);
  };

  useEffect(() => {
    checkAdmin();
  }, []);

  if (isAdmin) {
    return props.children;
  } else {
    return <Loader />;
  }
}
export default App;
